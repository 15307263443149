import React from "react";
import { StaticImage, getImage } from "gatsby-plugin-image";

import zoomImage from "../../images/programovanie-na-mieru/kedy-potrebujete-programovanie-na-mieru.jpg";
import zoomImage2 from "../../images/webdizajn/webdizajn-eshop-a-webstranka.jpg";
import zoomImage3 from "../../images/vyvoj-aplikacii/vyvoj-aplikacii-a-programov-na-mieru.jpg";
import zoomImage4 from "../../images/outsourcing-it/it-outsourcing-freelancerov-a-projektov.jpg";
import zoomImage5 from "../../images/web-na-mieru/web-na-mieru.jpg";

const ProgramovanieSolutionBox = (props) => {
  return (
    <div className={"solution-box loaded"}>
      {props.img == 1 ? (
        <div className="solution-img">
          <img
            className="img"
            src={zoomImage}
            alt={props.heading}
            loading="eager"
            title={props.heading}
          />
        </div>
      ) : props.img == 2 ? (
        <div className="solution-img">
          <img
            className="img"
            src={zoomImage2}
            alt={props.heading}
            loading="eager"
            title={props.heading}
          />
        </div>
      ) : props.img == 3 ? (
        <div className="solution-img">
          <img
            className="img"
            src={zoomImage3}
            alt={props.heading}
            loading="eager"
            title={props.heading}
          />
        </div>
      ) : props.img == 4 ? (
        <div className="solution-img">
          <img
            className="img"
            src={zoomImage4}
            alt={props.heading}
            loading="eager"
            title={props.heading}
          />
        </div>
      ) : (
        <div className="solution-img">
          <img
            className="img"
            src={zoomImage5}
            alt="Vývoj aplikácií a programov na mieru"
            loading="eager"
            title="Vývoj aplikácií a programov na mieru"
          />
        </div>
      )}

      <div className="solution-description">
        <h2>{props.heading}</h2>
        <p>{props.text}</p>
      </div>
    </div>
  );
};

export default ProgramovanieSolutionBox;
