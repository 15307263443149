import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import MainSectionZakaznickaZona from "../components/zakaznicka-zona/mainSection.js";

import "../styles/programovanie-na-mieru.scss";
import ContactUs from "../components/contactUs";
import ProgramovanieSolutionBox from "../components/programovanie-na-mieru/programovanieSolutionBox";
import ZonaFaqQuestion from "../components/zakaznicka-zona/zonaFaqQuestion.js";
import SliderSectionZona from "../components/zakaznicka-zona/sliderSection.js";
import References from "../components/homepage/references.js";
import ZonaClankySlider from "../components/zakaznicka-zona/zonaClankySlider";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";

import prvotnaKonzultaciaImg from "../images/programovanie-na-mieru/prvotna-konzultacia.jpg";
import navrhRieseniaImg from "../images/programovanie-na-mieru/analyza-navrh-riesenia.jpg";
import vyvojImg from "../images/programovanie-na-mieru/faza-programovania.jpg";
import testovanieImg from "../images/programovanie-na-mieru/faza-testovania.jpg";
import nasadenieDoPrevadzkyImg from "../images/programovanie-na-mieru/spustenie-projektu.jpg";
import podporaImg from "../images/programovanie-na-mieru/podpora-a-udrzba.jpg";

import unikatnostIcon from "../images/programovanie-na-mieru/icons/unikatnost.svg";
import integraciaIcon from "../images/programovanie-na-mieru/icons/integracia.svg";
import rastIcon from "../images/programovanie-na-mieru/icons/rast-a-skalovatelnost.svg";
import konkurencnaVyhodaIcon from "../images/programovanie-na-mieru/icons/konkurencna-vyhoda.svg";
import bezpecnostIcon from "../images/programovanie-na-mieru/icons/bezpecnost.svg";
import dlhodobeRieseniaIcon from "../images/programovanie-na-mieru/icons/dlhodobe-riesenia.svg";
import flexibilitaIcon from "../images/programovanie-na-mieru/icons/flexibilita.svg";
import usporaIcon from "../images/programovanie-na-mieru/icons/uspora.svg";

//import zonesColage from "../images/zakaznicka-zona/okna.jpg";

export const pageQuery = graphql`
  query {
    articles: wpCategory(slug: { eq: "programovanie-na-mieru" }) {
      posts {
        nodes {
          title
          excerpt
          slug
          featuredImage {
            node {
              altText
              title
              gatsbyImage(width: 1920)
              sourceUrl
            }
          }
        }
      }
    }
    mysqlProjects(url: { eq: "plusim" }) {
      url
      real_url
      client
      hashtag
      project_description
      case_study_title
      case_study_rok
      case_study_sluzby
      case_study_vyzva
      case_study_proces
      case_study_riesenie
      ogDescription
    }
    files: allFile(
      filter: {
        relativeDirectory: { eq: "plusim" }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    projekty: allMysqlProjects(sort: { fields: case_study_rok, order: DESC }) {
      nodes {
        url
        client
        case_study_description
      }
    }
    logosWhite: allFile(
      filter: {
        name: { eq: "logo-white" }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        relativeDirectory
        publicURL
      }
    }
  }
`;

const ZakaznickaZona = (pageQuery) => {
  const images = {};
  pageQuery.data.files.nodes.map((node) => {
    let index = node.name;
    images[index] = node;
  });

  return (
    <Layout pageName="programovanie-na-mieru-main">
      <SEO
        title="Programovanie na mieru - Sme správna voľba"
        description="Naprogramujeme vám digitálny produkt na mieru. Webstránku, E-shop, CRM, Aplikáciu, Informačný systém, B2B portál, Konfigurátor, či Zákaznícku zónu. Buďte pred konkurenciou o tri kroky vpred. Rýchlejší, modernejší, prozákazníckejší s vyššími ziskami. "
      />
      <MainSectionZakaznickaZona
        heading="Programovanie na mieru"
        id="lg"
        textHtml={
          <b>
            Naprogramujeme vám digitálny produkt na mieru. Webstránku, E-shop,
            CRM, Aplikáciu, Informačný systém, B2B portál, Konfigurátor, či
            Zákaznícku zónu. Buďte pred konkurenciou o tri kroky vpred.
            Rýchlejší, modernejší, prozákazníckejší s vyššími ziskami.
          </b>
        }
      />
      <section id="what-is-client-zone">
        <ProgramovanieSolutionBox
          img={1}
          heading="Kedy potrebujete programovanie na mieru?"
          text="Programovanie na mieru je potrebné v situáciách, kedy hotové riešenia alebo štandardné softvérové balíky nespĺňajú špecifické potreby vašej spoločnosti alebo vašich ľudí."
        />
      </section>

      {/*<section id="client-zone-colage">
        <img
          className="img"
          src={zonesColage}
          alt="Zákaznícke zóny"
          loading="lazy"
        />
      </section>*/}

      <section className="section-blog">
        <div className="banner-blog">
          <h2>
            Dôvody a situácie, kedy je programovanie na mieru najvhodnejšie
          </h2>
        </div>
      </section>
      <section id="case-study-info">
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={unikatnostIcon}
                alt="Unikátne požiadavky"
                title="Unikátne požiadavky"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Unikátne požiadavky{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Ak máte špecifické potreby, ktoré nespĺňa žiadne dostupné
              softvérové riešenie, môžete zvažovať vývoj na mieru.{" "}
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={integraciaIcon}
                alt="Integrácia so súčasnými systémami"
                title="Integrácia so súčasnými systémami"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Integrácia so súčasnými systémami{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Ak už vaša firma používa rôzne IT systémy a potrebujete, aby
              s nimi spolupracoval nový softvér bez problémov, môže byť potrebný
              vývoj na mieru.{" "}
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={rastIcon}
                alt="Rast a škálovateľnosť"
                title="Rast a škálovateľnosť"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Rast a škálovateľnosť{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Pre rýchlo rastúce spoločnosti alebo projekty, ktoré by mohli v
              budúcnosti zaznamenať veľký rast, môže byť na mieru vyrobený
              program, či aplikácia tá pravá voľba.{" "}
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={konkurencnaVyhodaIcon}
                alt="Konkurenčná výhoda"
                title="Konkurenčná výhoda"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Konkurenčná výhoda{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              V niektorých prípadoch môže mať vaša firma unikátny softvér, ktorý
              poskytuje konkurenčnú výhodu v porovnaní s „krabicovými“
              riešeniami.{" "}
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={bezpecnostIcon}
                alt="Vylepšená bezpečnosť"
                title="Vylepšená bezpečnosť"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Vylepšená bezpečnosť{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Ak vaša organizácia vyžaduje mimoriadne bezpečnostné opatrenia,
              môžete sa rozhodnúť pre vývoj na mieru, aby boli tieto potreby
              naplnené.{" "}
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={dlhodobeRieseniaIcon}
                alt="Dlhodobé riešenia"
                title="Dlhodobé riešenia"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Dlhodobé riešenia{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Krabicové alebo staršie riešenia môžu byť občas technologicky
              zastarané alebo ich podpora môže byť ukončená. Vývoj na mieru vám
              umožní zabezpečiť dlhodobú podporu a aktualizácie podľa vašich
              potrieb.{" "}
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={flexibilitaIcon}
                alt="Flexibilita"
                title="Flexibilita"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Flexibilita{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Viete získať väčšiu kontrolu nad prispôsobeniami, aktualizáciami a
              zmenami, ktoré chcete v budúcnosti implementovať.{" "}
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={usporaIcon}
                alt="Úspora času a zdrojov"
                title="Úspora času a zdrojov"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Úspora času a zdrojov{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Hoci počiatočná investícia môže byť vyššia, na mieru vyrobené
              programy a aplikácie môžp v dlhodobom horizonte ušetriť čas a
              zdroje tým, že presne zodpovedajú vašim potrebám a minimalizujete
              tak potrebu manuálnej práce alebo &quot;obchádzania&quot;
              problémov vašich zamestnancov.{" "}
            </p>
          </div>
        </div>
      </section>

      {/*<section id="case-study-process" className="case-study-process-mac">
        <div className="comparison2">
          <GatsbyImage
            className="mockup-notebook notebook-image"
            image={getImage(images["web-new"])}
            alt="Mac"
          />
        </div>
      </section>*/}

      <section className="section-blog">
        <div className="banner-blog">
          <p>
            Programovanie a vývoj na mieru je proces, pri ktorom sa softvér
            alebo program/aplikácia navrhuje a vytvára tak, aby presne
            zodpovedala vašim konkrétnym potrebám a požiadavkám. V kontraste k
            hotovým softvérovým riešeniam, ktoré sú dostupné &quot;krabicové
            riešenia&quot; a sú určené pre široké spektrum používateľov, vývoj
            na mieru sa zameriava na konkrétne potreby iba Vás ako klienta.{" "}
          </p>
        </div>
      </section>
      {/*<section id="case-study-info">
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={usporaIcon}
                alt="Úspora času"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Úspora času{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Zákaznícka zóna vám automatizuje mnohé procesy, čím významne šetrí
              čas. Vaši zamestnanci nemusia toľko energie míňať na telefonáty a
              e-maily od zákazníkov či riešenie prácnej administratívy a môžu sa
              radšej venovať dôležitejším úlohám.{" "}
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={znizenieIcon}
                alt="Zníženie nákladov"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Zníženie nákladov{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Keďže klientska zóna zastreší mnohé každodenné procesy, vaši
              zamestnanci nestrávia toľko času vystavovaním faktúr a objednávok
              či odpovedaním na časté otázky klientov. Ušetríte tak značnú časť
              pravidelných nákladov.{" "}
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={lojalitaIcon}
                alt="Vyššia lojalita zákazníkov"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Vyššia lojalita zákazníkov{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Klientsky portál vás odlíši od konkurencie a vašim zákazníkom
              poskytne veľmi príjemný používateľský zážitok. Vďaka tomu budú s
              radosťou dlhodobo využívať vaše služby a nakupovať produkty.{" "}
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={fungovanieIcon}
                alt="Nepretržité fungovanie podnikania"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Nepretržité fungovanie podnikania{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Zákaznícka zóna funguje 24 hodín denne, 7 dní v týždni. Klienti ju
              môžu naplno využívať aj v čase, keď sú všetci vaši zamestnanci
              doma alebo na dovolenke. Podnikanie tak nikdy nespí a môže vám
              generovať tržby počas celého dňa.{" "}
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={rizikoIcon}
                alt="Nižšie riziko chýb a straty dát"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Nižšie riziko chýb a straty dát{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Vďaka zákazníckemu portálu sa vám už nestane, že zabudnete na
              nejakú objednávku alebo sa vám stratia dôležité dáta. Všetky
              informácie nájdete na jednom mieste, v prehľadnej podobe.{" "}
            </p>
          </div>
        </div>
      </section>*/}

      <section id="online-business-solutions">
        <h2>Tu je stručný prehľad toho, ako tento proces funguje</h2>
        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={prvotnaKonzultaciaImg}
            alt="Počiatočná konzultácia"
            title="Počiatočná konzultácia"
            loading="lazy"
          />
          <div className="solution-description">
            <h3>1. Počiatočná konzultácia</h3>
            <p>
              Náš tím sa s vami stretáva, aby zistil, aké sú vaše požiadavky a
              očakávania od softvéru. Diskutuje sa o funkciách, grafike, návrhu,
              integrácii s existujúcimi systémami a iných dôležitých aspektoch.
            </p>
            {/*<br />
            <p className="bold">
              Prvé stretnutie je na nás a trvá 1 – 2 hodiny
            </p>*/}
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>2. Návrh riešenia</h3>
            <p>
              Na základe zhromaždených informácií vývojový tím vytvorí
              konceptuálny návrh riešenia, ktorý môže zahŕňať vizuálne
              prototypy, architektonické schémy a ďalšie dokumenty.
            </p>
          </div>
          <img
            className="img solution-img"
            src={navrhRieseniaImg}
            alt="Návrh riešenia"
            title="Návrh riešenia"
            loading="lazy"
          />
        </div>

        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={vyvojImg}
            alt="Vývoj"
            title="Vývoj"
            loading="lazy"
          />
          <div className="solution-description">
            <h3>3. Vývoj</h3>
            <p>
              Po schválení návrhu začína skutočný vývoj. Programátori píšu kód,
              dizajnéri pracujú na grafike a iní špecialisti sa môžu zaoberať
              databázami, bezpečnosťou a ďalšími aspektmi.
            </p>
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>4. Testovanie</h3>
            <p>
              Pred tým, než sa softvér dodá zákazníkovi, podstúpi dôkladné
              testovanie na odhalenie chýb, problémov s výkonom a iných
              potenciálnych problémov.
            </p>
          </div>
          <img
            className="img solution-img"
            src={testovanieImg}
            alt="Testovanie"
            title="Testovanie"
            loading="lazy"
          />
        </div>

        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={nasadenieDoPrevadzkyImg}
            alt="Nasadenie do prevádzky"
            title="Nasadenie do prevádzky"
            loading="lazy"
          />
          <div className="solution-description">
            <h3>5. Nasadenie do prevádzky</h3>
            <p>
              Po úspešnom testovaní sa softvér nasadí v Cloude alebo vašom
              prostredí. To môže zahŕňať inštaláciu na serveroch, integráciu s
              existujúcimi systémami alebo školenie užívateľov.
            </p>
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>6. Podpora a údržba</h3>
            <p>
              Po nasadení softvéru môže vývojový tím poskytnúť podporu, aby
              zabezpečil jeho bezproblémový chod a reagoval na prípadné problémy
              alebo potreby aktualizácií.
            </p>
          </div>
          <img
            className="img solution-img"
            src={podporaImg}
            alt="Podpora a údržba"
            title="Podpora a údržba"
            loading="lazy"
          />
        </div>
      </section>

      <References />

      <ZonaClankySlider clanky={pageQuery.data.articles} />

      <section id="zonaFaq">
        <h2>
          Často kladené otázky
          <StaticImage
            className="img"
            src="../../images/new-web/arrow.svg"
            alt="Šípka vpravo"
            placeholder="blurred"
          />
        </h2>

        <p>
          Pri vývoji softvéru na mieru sa často stretávame s množstvom otázok zo
          strany zákazníkov. Tu je niekoľko najčastejších otázok (FAQ) a ich
          stručné odpovede:
        </p>
        <br />
        <br />

        <ZonaFaqQuestion
          question="Koľko bude vývoj stáť?"
          answer={
            <>
              Cena závisí od zložitosti projektu, požadovaných funkcií,
              technologických nárokov a trvania vývoja. Presnú cenovú ponuku
              získate po podrobnom zhodnotení vášho projektu.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako dlho potrvá, kým bude môj softvér hotový?"
          answer={
            <>
              Podobne ako pri nákladoch, aj čas vývoja závisí od komplexnosti a
              rozsahu projektu. Po úvodnej konzultácii a plánovaní (fáza Návrh
              riešenia) vám li poskytneme odhadovaný časový rámec.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Aké technológie používate pri vývoji?"
          answer={
            <>
              Technológie sa líšia v závislosti od projektu. Pri návrhu riešenia
              volíme správne technológie, ktoré najlepšie budú najlepšie
              vyhovovať požiadavkám vášho projektu.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako budete zabezpečovať môj softvér?"
          answer={
            <>
              Bezpečnosť je pri niektorých projektoch kľúčová, a preto pri
              vývoji používame najnovšie technológie, osvedčené bezpečnostné
              postupy a prípadné penetračné testy.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako bude prebiehať komunikácia počas vývoja?"
          answer={
            <>
              Efektívna komunikácia je základná požiadavka nie len pre projekt,
              ale aj stabilné partnerstvo. Budeme vás pravidelne informovať o
              postupe a budeme k dispozícii pre otázky alebo pripomienky.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Čo ak budem potrebovať aktualizácie alebo úpravy v budúcnosti?"
          answer={
            <>
              Vývoj na mieru často zahŕňa možnosť ďalšieho rozširovania alebo
              úprav. Súčasťou návrhu riešenia sú aj konzultácie
              o aktualizáciách, údržbách, monitoringu a ďalšej podpore.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Poskytujete aj podporu a údržbu po dokončení projektu?"
          answer={
            <>
              Vo väčšine prípadov áno. Podmienky podpory a údržby si určime
              v zmluve.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako riešite testovanie a kvalitu kódu?"
          answer={
            <>
              Testovanie je neoddeliteľnou súčasťou vývoja. Na zabezpečenie
              kvality používamé rôzne formy testovania (manuálne, automatické,
              testy záťaže atď.).
            </>
          }
        />
      </section>
      <ContactUs
        text={<>Máte záujem o Programovanie na mieru?</>}
        button="Napíšte nám e-mail"
      />
    </Layout>
  );
};

export default ZakaznickaZona;
